import { Box, Center, Divider, useToken } from '@hausgold/designsystem';
import {
  Dashboard,
  Help,
  House,
  InfoOutline,
  Rooms,
} from '@hausgold/designsystem/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import SidebarMenuItem from './SidebarMenuItem';

/**
 * Sidebar menu
 *
 * Renders the linked menu for the sidebar and delete the notification token on backend on logout.
 *
 * @param {import('@hausgold/designsystem').BoxProps} [rest] The rest of the props
 * @returns {React.Component}
 */
const SidebarMenu = ({ ...rest }) => {
  const { t } = useTranslation();

  /*
   * CAUTION: Magic number. Has to be synced with `Sidebar`'s `px`.
   *
   * This makes it possible to stretch the menu past the sidebar's padding. The
   * reason for this is so that we don't have to fiddle with paddings (i.e.
   * define padding for content above and below the menu, but not on the menu).
   */
  const sidebarMenuPx = useToken('space', 4);

  /**
   * Sidebar items
   * @typedef {Array<SidebarMenuItem>} SidebarMenuItems
   */
  const NAVIGATION_ITEMS = useMemo(
    () => [
      {
        title: t('sidebar.menu.homepage'),
        href: '/dashboard',
        activeOn: '/dashboard',
        icon: <Dashboard />,
      },
      {
        title: t('sidebar.menu.properties'),
        href: '/properties',
        activeOn: '/lead/[...detailParams]',
        icon: <House />,
      },
      {
        title: t('sidebar.menu.help'),
        href: '/help',
        activeOn: '/help',
        icon: <Help />,
      },
      {
        title: t('sidebar.menu.settings'),
        href: '/settings',
        icon: <InfoOutline />,
      },
      {
        title: t('sidebar.menu.logout'),
        href: '/logout',
        icon: <Rooms />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box
      {...rest}
      w={`calc(100% + ${sidebarMenuPx} * 2) `}
      marginLeft={`-${sidebarMenuPx}`}
      marginRight={`-${sidebarMenuPx}`}
    >
      <Center px="16px">
        <Divider opacity="80%" />
      </Center>

      <Box as="ul" role="navigation">
        {NAVIGATION_ITEMS.map((item) => (
          <SidebarMenuItem key={item.href} mb="1px" {...item} />
        ))}
      </Box>
    </Box>
  );
};

export default SidebarMenu;

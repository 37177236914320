import NextLink from 'next/link';
import { Flex, Link, LogoMaklerportal } from '@hausgold/designsystem';
import { useHeaderHeight } from 'app-utils/contexts/headerHeight';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';

/**
 * The header part of the sidebar; contains the logo.
 * @param {import('@hausgold/designsystem').BoxProps} props
 */
const SidebarHeader = (props) => {
  const [headerHeight] = useHeaderHeight();
  const { isWindowControlsOverlay } = usePWADisplayMode();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{
        appRegion: 'drag',
        position: isWindowControlsOverlay && 'sticky',
        top: 0,
        left: 0,
        zIndex: 'sticky',
        height: `env(titlebar-area-height, ${headerHeight})`,
        bgColor: 'darkBlue.200',
      }}
      {...props}
    >
      <NextLink href="/" passHref>
        <Link
          data-testid="logoWrapper"
          p={6}
          d="block"
          sx={{
            appRegion: 'no-drag',
          }}
        >
          <LogoMaklerportal isInverted data-testid="logo" />
        </Link>
      </NextLink>
    </Flex>
  );
};

export default SidebarHeader;

import { Box, Flex, HeadingH4, Text } from '@hausgold/designsystem';
import NextLink from 'next/link';
import { useMemo } from 'react';
import { computeRelativeDate } from 'app-utils/compute/computeRelativeDate';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ComponentWithAs } from '@chakra-ui/system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IconProps } from '@chakra-ui/icon';

export interface NotificationCardProps {
  /**
   * The headline of the card.
   */
  headline: string;
  /**
   * The descriptive body text of the card.
   */
  body: string;
  /**
   * The icon which is shown within the card.
   */
  Icon: ComponentWithAs<'svg', IconProps>;
  /**
   * A date time as string used to show how old the message is.
   */
  timestamp?: string;
  /**
   * If set, makes the card clickable, so the user can be navigated to the page the notification related to.
   */
  link?: string;
  /**
   * Optional click handler on the notification card. Only used if `link` is set.
   */
  handleClick?: () => void;
}

/**
 * Renders a single notification card based on given data.
 * Besides the headline and descriptive body message it can show a relative date and handle user clicks if configured.
 */
const NotificationCard = ({
  headline,
  body,
  Icon,
  timestamp,
  link,
  handleClick,
}: NotificationCardProps) => {
  // Get relative date.
  const timePast = useMemo(
    () => computeRelativeDate(timestamp!),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timestamp]
  );

  // Card without a link.
  if (!link) {
    return (
      <Flex
        marginTop={1}
        paddingX={4}
        paddingY={3}
        maxWidth="400px"
        _hover={{
          backgroundColor: 'gray.200',
        }}
        data-testid="notificationCard"
      >
        {!!Icon && <Icon color="blue.400" mr={4} boxSize={6} />}
        <Box>
          <HeadingH4 mb={2}>{headline}</HeadingH4>

          <Text mb={1}>{body}</Text>

          {!!timePast && (
            <Text mb={0} textAlign="right" color="gray.400">
              {timePast}
            </Text>
          )}
        </Box>
      </Flex>
    );
  }

  // Card with link.
  return (
    <NextLink href={link} passHref>
      <Flex
        as="a"
        marginTop={1}
        paddingX={4}
        paddingY={3}
        maxWidth="400px"
        _hover={{
          backgroundColor: 'gray.200',
        }}
        onClick={handleClick}
        data-testid="notificationCard"
      >
        {!!Icon && <Icon color="blue.400" mr={4} boxSize={6} />}
        <Box>
          <HeadingH4 mb={2}>{headline}</HeadingH4>

          <Text mb={1}>{body}</Text>

          {!!timePast && (
            <Text mb={0} textAlign="right" color="gray.400">
              {timePast}
            </Text>
          )}
        </Box>
      </Flex>
    </NextLink>
  );
};

export default NotificationCard;
